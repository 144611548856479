import React from 'react'
import Layout from '../components/Layout'
import * as styles from '../styles/about.module.css'
import { graphql } from 'gatsby'
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';


export default function About() {
  const { t, i18n } = useTranslation();

  return (
    <Layout>
      <div className={styles.layoutAbout}>
        <div className={styles.intro}>

          <h1 className="header"><Trans>Qui suis-je?</Trans></h1>

            <p className={styles.text}>
              <Trans>Créative, organisée et avide d'apprendre, ma curiosité est le moteur de mes aventures professionnelles.
                Actuellement en Master 1 Management de Projet, Innovation et Créativité à Cannes, je suis à la recherche d’une alternance comme Cheffe de projet
                dans le secteur des Industries Créatives.</Trans>
                <br/> <br/>
              <Trans>Mon parcours actuel m'a conduit à appliquer la gestion et la réalisation de projets Agiles,
                que ce soit sur des thématiques tech (création de site web et d’une application mobile de A à Z en React Native),
                ou la création de jeux de société (qui seront présentés au Festival International du Jeu de 2024).</Trans>
                <br/>
              <Trans>Professionnellement je m'adapte à n'importe quel environnement et je me sens à l'aise pour diriger les équipes lorsque cela est nécessaire.
                Je maîtrise l’organisation, la priorisation, la gestion du temps et la résolution de problèmes.
                Je suis ouverte et j’apprécie travailler avec des personnes d’horizons et de parcours différents car cela me semble toujours enrichir tant les processus que le résultat.</Trans>
              {/*<br/><br/>*/}
              {/*Si vous voulez en savoir plus sur moi, n'hésitez pas à me contacter.*/}

            </p>

        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
  `;